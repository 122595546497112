.box-footer {
      width: 100%;

    padding-top: 92px;
    padding-bottom: 92px;
    background: linear-gradient(to right, #F45197, #ED0876);

}
.footer-center {
    width: 100%;
    display: flex;
    justify-content: center;
}

.footer-center  .col-12 .col-md-4 {
padding: 40px;
} 

.box-footer-bottom{
    background-color: #4A4A4A;
    width: 100%;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
}
.layer1-image {
    max-width: 95px;
    width: 100%;
    height: 100%;
    max-height: 33px;
}
.company-bbpf {
    font-size: 28px;
    margin-top: 16px;
    color: #FFFFFF;
    font-weight: 600;
}
.company-address {
    font-size: 18px;
    color: #FFFFFF;
    
}
.contact-us {
    font-size: 24px;
    color: #FFFFFF;
    font-weight: 600;
}
.phone-line {
    width: 24px;
    height: 24px;
    margin-right: 6px;
}
.fb-icon{
    width: 32px;
    height: 32px;
}
.social-icons {
    display: flex; /* Use flexbox for horizontal alignment */
}

.fb-icon {
    margin-right: 10px; /* Optional: Add some spacing between icons */
}
.hr {
    margin-left: 0%;
    background-color: #BCCCD6;
    width: 100%;
    height: 1.19px;
}
.hr-policy {
    background-color: #BCCCD6;
    width: 1px;
    height: 28px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: -4px;
}
@media screen {
    
}

.policy-bottom {
    display: flex;
    flex-direction: row;
}
/* .policy-none {
    margin-top: -14px;
    display: block;
} */

@media screen and (max-width: 576px) {
    .policy-none {
        display: none;
    }
  

    .box-footer-bottom {
        padding-top: 21px;
        height: 112px;
        padding-left: 16px;
        padding-bottom: 33px;
        display: flex;
        flex-direction: column; 
        align-items: flex-start;
    }
/*     .policy-bottom {
    
        display: flex;
        flex-direction: row;
    } */
    /* .hr-policy {
   
        margin-top: 16px;
    } */
 
}